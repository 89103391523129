import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { ruRU as Ru } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    palette: palette,
    typography: typography as TypographyOptions,
    overrides,
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  },
  Ru
);

export default theme;
