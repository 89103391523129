import { createStyles, Theme } from '@material-ui/core';

const LoginViewStyles = (theme: Theme) => createStyles({
  root: {
    paddingTop: '70px'
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 20,
    paddingBottom: 50,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    textAlign: 'center'
  },
  textField: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.4)'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #5285cc'
      }
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#5285cc'
    }
  },
  signInButton: {
    marginTop: '50px',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontSize: '18px'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'yellow !important'
  }
});

export default LoginViewStyles;