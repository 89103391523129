import { combineReducers } from 'redux';
import AuthReducer from './auth/reducer';
import { AUTH_LOGOUT, AuthActionTypes, AuthState } from './auth/types';

//snackbar
import SnackbarReducer from './snackbar/reducer';
import { SnackbarState } from './snackbar/types';

export type RootState = {
  auth: AuthState;
  snackbar: SnackbarState;
};

const appReducer = combineReducers({
  auth: AuthReducer,
  snackbar: SnackbarReducer,
});

const rootReducer = (state: RootState | undefined, action: AuthActionTypes) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
