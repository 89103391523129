import React from 'react';

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     //logErrorToMyService(error, errorInfo);
  // }

  render() {

    let marginTop = '300px';

    if (this.state.hasError) {
      // @ts-ignore
      const wrappedComponentName = this.props.children?.type?.WrappedComponent?.name;
      const componentName = wrappedComponentName ?? '';
      return <h4 style={{ marginTop, textAlign: 'center' }}>Произошла ошибка при загрузке
        компонента {componentName}</h4>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;