import customAxios from '../../../utils/customAxios';
import apiRoutes from '../../../config/apiRoutes';
import { Dispatch } from 'redux';
import {
  AUTH_AUTO_FAIL,
  AUTH_AUTO_SUCCESS,
  AUTH_ERASE_ERROR,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_START,
  AUTH_SUCCESS,
  AuthActionTypes,
} from '../types';
import { User } from '../../../models/User';

export const logout = (): AuthActionTypes => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiration');
  localStorage.removeItem('user');

  return {
    type: AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expiration: number) => {
  return (dispatch: Dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expiration * 1000);
  };
};

export const authCheckState = () => {
  return (dispatch: Dispatch) => {
    const token = localStorage.getItem('token');

    if (!token) {
      dispatch(authAutoFail());
    } else {
      const user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user') as string)
        : '';
      const expirationDate = new Date(parseInt(localStorage.getItem('tokenExpiration') as string));

      if (expirationDate > new Date()) {
        dispatch(authAutoSuccess(token, user));
        // dispatch(
        //   checkAuthTimeout(
        //     (expirationDate.getTime() - new Date().getTime()) / 1000
        //   )
        // );
      } else {
        dispatch(logout());
      }
    }
  };
};

export const login = (login: string, password: string) => {
  return (dispatch: Dispatch) => {
    const authData = { login, password };

    dispatch(authStart());

    customAxios
      .post(apiRoutes.LOGIN, authData)
      .then((response) => {
        // console.log(response);

        const { access_token, user = '', expires } = response.data.data;
        // console.log(access_token);
        const expirationDate = new Date(new Date().getTime() + expires * 1000);
        const expirationDateToStore = expirationDate.getTime().toString();
        const userToStore = JSON.stringify(user);
        localStorage.setItem('token', access_token);
        localStorage.setItem('user', userToStore);
        localStorage.setItem('tokenExpiration', expirationDateToStore);

        dispatch(authSuccess({ token: access_token, user }));

        //dispatch(checkAuthTimeout(response.data.expires));
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(authFail(error.response));
      });
  };
};

export const authStart = (): AuthActionTypes => {
  return { type: AUTH_START };
};

export const authSuccess = (payload: { user: User; token: string }): AuthActionTypes => {
  return {
    type: AUTH_SUCCESS,
    payload: payload,
  };
};

export const authAutoSuccess = (token: string, user: User): AuthActionTypes => {
  return {
    type: AUTH_AUTO_SUCCESS,
    token: token,
    user: user,
  };
};

export const authAutoFail = (): AuthActionTypes => {
  return {
    type: AUTH_AUTO_FAIL,
  };
};

export const authFail = (error: any): AuthActionTypes => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};

export const authEraseError = (): AuthActionTypes => {
  return {
    type: AUTH_ERASE_ERROR,
  };
};
