import { createStyles } from '@material-ui/core/styles';

const DocumentStyles = () =>
  createStyles({
    formControl: {
      minWidth: '100%',
      width: '100%',
      textAlign: 'left',
    },
    spinner: {
      marginTop: '15px',
    },
    saveBtn: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '14px',
      width: '200px',
      display: 'block',
      margin: '40px auto',
    },
    backLink: {
      display: 'flex',
      alignItems: 'center',
      color: '#263238',
      textDecoration: 'none',
      marginTop: '10px',
      '& svg': {
        transition: '0.3s all',
      },
      '&:hover': {
        '& svg': {
          transform: 'translateX(-5px)',
        },
      },
    },
  });

export default DocumentStyles;
