import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

//redux
import { useDispatch } from 'react-redux';
import { logout } from '../../../../../../store/auth/actions';

//ui
import { List, ListItem, Button, Collapse, withStyles } from '@material-ui/core';

//icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

//styles
import SidebarNavStyles from './SidebarNavStyles';

type Props = {
  classes: {
    root: string;
    item: string;
    button: string;
    icon: string;
    active: string;
    nested: string;
    collapseIcon: string;
  };
  pages: any[];
  className: string;
};

const CustomRouterLink = forwardRef<HTMLDivElement>((props: any, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav: React.FC<Props> = (props) => {
  const { pages, classes, className, ...rest } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState<any>({
    documents: true,
  });

  const handleClick = (prop: any) => () => {
    setOpen({ ...open, [prop]: !open[prop] });
  };

  const handleLogout = (): void => {
    dispatch(logout());
  };

  return (
    <List {...rest} className={classes.root}>
      {pages.map((page) => {
        let listItem = (
          <ListItem className={classes.item} key={page.title} disableGutters>
            <Button
              // @ts-ignore
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}>
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        );

        if (page.href === '/logout') {
          listItem = (
            <ListItem className={classes.item} key={page.title} disableGutters>
              <Button className={classes.button} onClick={handleLogout}>
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          );
        }

        if (page?.submenu) {
          listItem = (
            <React.Fragment key={page.title}>
              <ListItem className={classes.item} disableGutters>
                <Button className={classes.button} onClick={handleClick(page?.name)}>
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                  {open[page?.name] ? (
                    <span className={classes.collapseIcon}>
                      <ExpandLess />
                    </span>
                  ) : (
                    <span className={classes.collapseIcon}>
                      <ExpandMore />
                    </span>
                  )}
                </Button>
              </ListItem>
              <Collapse in={open[page?.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page?.submenu.map((subpage: any) => {
                    return (
                      <ListItem
                        className={`${classes.item} ${classes.nested}`}
                        disableGutters
                        key={subpage.title}>
                        <Button
                          // @ts-ignore
                          activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={subpage.href}>
                          <div className={classes.icon}>{subpage.icon}</div>
                          {subpage.title}
                        </Button>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </React.Fragment>
          );
        }

        return listItem;
      })}
    </List>
  );
};

export default withStyles(SidebarNavStyles)(SidebarNav);
