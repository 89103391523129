import React, { FC, useState } from 'react';
import apiRoutes from '../../config/apiRoutes';

//ui
import { Button, TextField, CircularProgress, withStyles } from '@material-ui/core';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import CustomDialog from '../../utils/CustomDialog';
import { resolveError } from '../../utils/resolveError';

//styles
import GlobalStyles from '../styles/GlobalStyles';

type Props = {
  id: number;
  name_ru: string;
  name_ua: string;
  open: boolean;
  setOpen: Function;
  fetchCategories: Function;
  classes: {
    documentTextField: string;
    documentSaveButton: string;
  };
};

const EditCategories: FC<Props> = (props) => {
  const { classes, id, name_ru, name_ua, open, setOpen, fetchCategories } = props;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name_ru: name_ru,
    name_ua: name_ua,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | String>(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeValue = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    try {
      const { data } = await customAxios.put(`${apiRoutes.CATEGORIES}/${id}`, values);
      dispatch(setSnackbar(true, 'success', data.data.message));
      fetchCategories();
      handleCloseDialog();
    } catch (error) {
      setError(resolveError(error.response));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      title="Редактирование категории"
      openDialog={open}
      toggleDialog={handleCloseDialog}>
      <form noValidate>
        <TextField
          fullWidth
          label="Название (Укр)"
          variant="outlined"
          className={classes.documentTextField}
          value={values.name_ua}
          onChange={handleChangeValue('name_ua')}
        />
        <TextField
          fullWidth
          label="Название (Рус)"
          variant="outlined"
          className={classes.documentTextField}
          value={values.name_ru}
          onChange={handleChangeValue('name_ru')}
        />
        {error && <div className="error">{error}</div>}
        <Button
          className={classes.documentSaveButton}
          color="primary"
          type="submit"
          variant="contained"
          onClick={handleSubmit}>
          {loading && <CircularProgress size={20} style={{ color: 'white', marginRight: '5px' }} />}
          Сохранить
        </Button>
      </form>
    </CustomDialog>
  );
};

export default withStyles(GlobalStyles)(EditCategories);
