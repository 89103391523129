import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 64,
    height: '100%',
  },
  content: {
    height: '100%',
  },
}));


interface MinimalProps {
  children: React.ReactNode,
  className: string,
};

const Minimal = (props: MinimalProps) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={`${classes.root} minimal-container`}>
      <Topbar />
      <main className={`${classes.content} minimal-main`}>{children}</main>
    </div>
  );
};

export default Minimal;