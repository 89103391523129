import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import apiRoutes from '../../config/apiRoutes';

import AddDocumentType from './AddDocumentType';
import EditDocumentType from './EditDocumentType';

//utils
import customAxios from '../../utils/customAxios';
import { resolveError } from '../../utils/resolveError';
import ErrorMessage from '../../utils/ErrorMessage';
import materialTableLocalization from '../../utils/materialTableLocalization';
import PageTitle from '../../utils/PageTitle';

//ui
import MaterialTable from '@material-table/core';
import { Paper } from '@material-ui/core';

type TDocumentTypes = {
  id: number;
  name_ru: string;
  name_ua: string;
  created_at: Date;
  updated_at: Date;
};

const columns = [
  {
    title: 'id',
    field: 'id',
  },
  {
    title: 'Название (Укр)',
    field: 'name_ua',
  },
  {
    title: 'Название (Рус)',
    field: 'name_ru',
  },
  {
    title: 'Дата обновления',
    field: 'updatedAt',
  },
  {
    title: 'Дата создания',
    field: 'createdAt',
  },
];

const DocumentTypes = () => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [editData, setEditData] = useState<null | TDocumentTypes>(null);
  const [open, setOpen] = useState(false);

  const fetchDocumentTypes = async () => {
    setLoading(true);
    try {
      const { data } = await customAxios.get(apiRoutes.DOCUMENT_TYPES);
      setDocumentTypes(data.data);
    } catch (error) {
      setError(resolveError(error.response));
    } finally {
      setLoading(false);
    }
  };

  const getTransformedData = () => {
    const result = documentTypes?.map((item: TDocumentTypes) => {
      const modifiedCreatedAt = format(new Date(item?.created_at), 'dd.MM.yyyy');
      const modifiedUpdatedAt = format(new Date(item?.updated_at), 'dd.MM.yyyy');

      return {
        createdAt: modifiedCreatedAt,
        updatedAt: modifiedUpdatedAt,
        ...item,
      };
    });
    return result;
  };

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  return (
    <div className="wrap">
      <PageTitle title="Типы документов" />
      <div style={{ textAlign: 'right' }}>
        <AddDocumentType fetchDocumentTypes={fetchDocumentTypes} />
      </div>
      <Paper elevation={4}>
        {error && !loading ? (
          <ErrorMessage error={error} />
        ) : (
          <MaterialTable
            title=""
            isLoading={loading}
            columns={columns}
            data={getTransformedData()}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Редактирование',
                onClick: (event, rowData: any) => {
                  setOpen(true);
                  setEditData(rowData);
                },
              },
            ]}
            options={{
              filtering: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100],
              emptyRowsWhenPaging: false,
            }}
            localization={materialTableLocalization}
          />
        )}
      </Paper>
      {open && editData && (
        <EditDocumentType
          open={open}
          name_ru={editData.name_ru}
          name_ua={editData.name_ua}
          id={editData.id}
          setOpen={setOpen}
          fetchDocumentTypes={fetchDocumentTypes}
        />
      )}
    </div>
  );
};

export default DocumentTypes;
