import { createStyles, Theme } from '@material-ui/core/styles';

const CustomDialogStyles = (theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      padding: '10px 0',
      fontSize: '20px',
    },

    closeButton: {
      position: 'absolute',
      right: '5px',
      top: '5px',
      color: theme.palette.grey[500],
    },
  });

export default CustomDialogStyles;
