import React, { FC } from 'react';

//ui
import { Typography } from '@material-ui/core';

type Props = {
  title: string;
};

const PageTitle: FC<Props> = ({ title }) => {
  return (
    <Typography
      align="center"
      gutterBottom
      variant="h4"
      style={{ fontSize: '24px', marginBottom: '30px' }}>
      <span style={{ color: '#37474f' }}>{title}</span>
    </Typography>
  );
};

export default PageTitle;
