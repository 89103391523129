import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import apiRoutes from '../../config/apiRoutes';
import appRoutes from '../../config/appRoutes';

//utils
import customAxios from '../../utils/customAxios';
import { resolveError } from '../../utils/resolveError';
import ErrorMessage from '../../utils/ErrorMessage';
import materialTableLocalization from '../../utils/materialTableLocalization';
import PageTitle from '../../utils/PageTitle';

//ui
import { withStyles, Paper, IconButton } from '@material-ui/core';
import MaterialTable from '@material-table/core';

// //icons
import DescriptionIcon from '@material-ui/icons/Description';

//styles
import DocumentStyles from './DocumentStyles';

type Data = {
  document_number: string;
  document_type: {
    name_ru: string;
  };
  brand: {
    name: string;
  };
  keywords: string;
  issued_on: Date;
  expires_on: Date;
  categories: Array<{ name_ru: string }>;
  path: string;
};

const DocumentsCatalog = () => {
  const history = useHistory();
  // const [documentTypes, setDocumentTypes] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | String>(null);

  const getTransformedData = () => {
    const result = data?.map((item: Data) => {
      const modifiedIssuedOn = format(new Date(item?.issued_on), 'dd.MM.yyyy');
      // const modifiedExpiresOn = format(new Date(item?.expires_on), 'dd.MM.yyyy');
      const modifiedCategories = item?.categories?.map((cat: { name_ru: string }) => cat?.name_ru);

      return {
        issuedOn: modifiedIssuedOn,
        // expiresOn: modifiedExpiresOn,
        catName: modifiedCategories.join(', '),
        ...item,
      };
    });
    return result;
  };

  // const fetchDocumentTypes = async () => {
  //   try {
  //     const { data } = await customAxios.get(apiRoutes.DOCUMENT_TYPES);
  //     setDocumentTypes(data.data);
  //   } catch (error) {
  //     console.log(resolveError(error.response));
  //   }
  // };

  // const transformDocumentTypes =
  //   documentTypes &&
  //   documentTypes.reduce((acc: Object, elem: { id: number; name_ru: string }) => {
  //     return {
  //       [elem.id]: elem.name_ru,
  //       ...acc,
  //     };
  //   }, {});

  const fetchData = async () => {
    try {
      const { data } = await customAxios.get(apiRoutes.DOCUMENTS);
      setData(data.data);
    } catch (error) {
      setError(resolveError(error.response));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchDocumentTypes();
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        title: '',
        field: 'path',
        width: 80,
        filtering: false,
        sorting: false,
        render: (rowData: Data) => (
          <a href={`${rowData.path}`} target="_blank" rel="noreferrer">
            <IconButton aria-label="watch" style={{ color: '#263238' }}>
              <DescriptionIcon color="inherit" />
            </IconButton>
          </a>
        ),
      },
      {
        title: '№ документа',
        field: 'document_number',
      },

      {
        title: 'Тип документа',
        field: 'document_type.name_ru',
        // lookup: transformDocumentTypes,
      },
      {
        title: 'Категория',
        field: 'catName',
      },
      {
        title: 'Бренд',
        field: 'brand.name',
      },
      {
        title: 'Ключевые слова',
        field: 'keywords',
      },
      {
        title: 'Дата выпуска',
        field: 'issuedOn',
      },
    ],
    []
  );

  return (
    <div className="wrap">
      <PageTitle title="Все документы" />
      <Paper elevation={4}>
        {error && !loading ? (
          <ErrorMessage error={error} />
        ) : (
          <MaterialTable
            title=""
            isLoading={loading}
            columns={columns}
            data={getTransformedData()}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Редактирование',
                onClick: (event, rowData: any) => {
                  history.push(`${appRoutes.EDIT_DOCUMENT.url}/${rowData.id}`);
                },
              },
            ]}
            options={{
              filtering: true,
              pageSize: 15,
              pageSizeOptions: [15, 50, 100],
              emptyRowsWhenPaging: false,
            }}
            localization={materialTableLocalization}
          />
        )}
      </Paper>
    </div>
  );
};

export default withStyles(DocumentStyles)(DocumentsCatalog);
