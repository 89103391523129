import React, { FC, useState } from 'react';
import apiRoutes from '../../config/apiRoutes';

//ui
import { Button, TextField, CircularProgress, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import CustomDialog from '../../utils/CustomDialog';
import { resolveError } from '../../utils/resolveError';

//styles
import GlobalStyles from '../styles/GlobalStyles';

type Props = {
  classes: {
    documentButton: string;
    documentTextField: string;
    documentSaveButton: string;
  };
  fetchCategories: Function;
};

const AddDocumentCategory: FC<Props> = ({ classes, fetchCategories }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    name_ru: '',
    name_ua: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | String>(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeValue = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const resetForm = () => {
    setError(null);
    setValues({
      name_ru: '',
      name_ua: '',
    });
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    try {
      const { data } = await customAxios.post(apiRoutes.CATEGORIES, values);
      dispatch(setSnackbar(true, 'success', data.data.message));
      fetchCategories();
      handleCloseDialog();
      resetForm();
    } catch (error) {
      setError(resolveError(error.response));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.documentButton}
        onClick={() => setOpen(true)}>
        <AddIcon />
      </Button>
      <CustomDialog title="Добавление категории" openDialog={open} toggleDialog={handleCloseDialog}>
        <form noValidate className="document-form">
          <TextField
            fullWidth
            label="Название (Укр)"
            variant="outlined"
            className={classes.documentTextField}
            value={values.name_ua}
            onChange={handleChangeValue('name_ua')}
          />
          <TextField
            fullWidth
            label="Название (Рус)"
            variant="outlined"
            className={classes.documentTextField}
            value={values.name_ru}
            onChange={handleChangeValue('name_ru')}
          />
          {error && <div className="error">{error}</div>}
          <Button
            className={classes.documentSaveButton}
            color="primary"
            type="submit"
            variant="contained"
            onClick={handleSubmit}>
            {loading && (
              <CircularProgress size={20} style={{ color: 'white', marginRight: '5px' }} />
            )}
            Сохранить
          </Button>
        </form>
      </CustomDialog>
    </div>
  );
};

export default withStyles(GlobalStyles)(AddDocumentCategory);
