import { createStyles, Theme } from '@material-ui/core/styles';

const GlobalStyles = (theme: Theme) =>
  createStyles({
    documentButton: {
      marginBottom: '20px',
    },
    documentTextField: {
      marginTop: theme.spacing(2),
      '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.4)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #5285cc',
        },
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5285cc',
      },
    },
    documentSaveButton: {
      display: 'flex',
      fontSize: '14px',
      margin: '20px auto 30px',
      padding: '10px 50px',
    },
  });

export default GlobalStyles;
