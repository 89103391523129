import { useState, useEffect } from 'react';
import { getUnixTime, subDays, format, startOfDay, endOfDay } from 'date-fns';
import apiRoutes from '../../config/apiRoutes';
import { ReportsHeader, ReportsBody } from './components';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import { resolveError } from '../../utils/resolveError';

//types
import { ReportType, DateType } from './ReportsTypes';

type ReportState = {
  data: ReportType[];
  loading: boolean;
  error: null | string;
};

const columns = [
  { title: 'Ключевое слово', field: 'keywords' },
  { title: 'Бренд', field: 'brand' },
  { title: 'Категория', field: 'category' },
  { title: 'Тип документа', field: 'document_type' },
  { title: 'Дата и время ответа', field: 'created_at' },
];

const REPORT_NAME = `Отчет_по_неудачным_запросам_${format(new Date(), 'dd_MM_yyyy__HH_mm')}.xlsx`;

export default function UnsuccessfulUserRequests() {
  const dispatch = useDispatch();
  const [date, setDate] = useState<DateType>({
    from: startOfDay(subDays(new Date(), 1)),
    to: endOfDay(new Date()),
  });

  const [report, setReport] = useState<ReportState>({
    data: [],
    loading: false,
    error: null,
  });

  const [btnLoading, setBtnLoading] = useState(false);

  const conversionData = (data: ReportType[]): ReportType[] => {
    return data.map((report: ReportType) => {
      return {
        ...report,
        created_at: format(new Date(report.created_at), 'dd.MM.yyyy HH:mm'),
      };
    });
  };

  const fetchReport = (): void => {
    setReport({ data: [], loading: true, error: null });

    const postBody = { from: getUnixTime(date.from), to: getUnixTime(date.to) };

    customAxios
      .post(apiRoutes.REQUESTS_HISTORY_FIND_BY_PERIOD, postBody)
      .then(({ data: { data } }) => {
        setReport({ data: conversionData(data), loading: false, error: null });
      })
      .catch((error) => {
        setReport({ data: [], loading: false, error: resolveError(error.response) });
      });
  };

  const getExcelReport = async (): Promise<void> => {
    if (btnLoading) return;
    setBtnLoading(true);
    const postBody = { from: getUnixTime(date.from), to: getUnixTime(date.to) };

    try {
      const { data } = await customAxios.post(
        apiRoutes.REQUESTS_HISTORY_GET_EXCEL_REPORT,
        postBody,
        {
          responseType: 'arraybuffer',
        }
      );
      fileDownloadHandler(data);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Файл не найден'));
    } finally {
      setBtnLoading(false);
    }
  };

  const downloadBlob = (blob: Blob, filename: string): HTMLAnchorElement => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename || 'download';

    link.click();

    setTimeout(() => {
      link.remove();
      URL.revokeObjectURL(url);
    });

    return link;
  };

  const fileDownloadHandler = (response: Blob): void => {
    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const downloadLink = downloadBlob(blob, REPORT_NAME);

    document.body.appendChild(downloadLink);
  };

  const onChangeDate = (prop: string) => (data: Date) => {
    setDate({ ...date, [prop]: data });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchReport, []);

  return (
    <div className="wrap">
      <ReportsHeader
        title="Неудачные запросы пользователей"
        date={date}
        loading={report.loading}
        btnLoading={btnLoading}
        onChangeDate={onChangeDate}
        fetchReport={fetchReport}
        getExcelReport={getExcelReport}
      />

      <ReportsBody
        loading={report.loading}
        error={report.error}
        data={report.data}
        columns={columns}
        filtering={false}
      />
    </div>
  );
}
