import { SET_SNACKBAR, SetSnackbarActionTypes, SnackbarState } from '../types';

const initState: SnackbarState = {
  open: false,
  typeAlert: 'success',
  message: '',
};

const SnackbarReducer = (state = initState, action: SetSnackbarActionTypes): SnackbarState => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { open, message, typeAlert } = action;
      return {
        ...state,
        open,
        typeAlert,
        message,
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
