import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, createStyles, Toolbar } from '@material-ui/core';
import allo_logo from '../../../../images/logo_allo_white.svg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      boxShadow: 'none',
    },
  })
);

const Topbar = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={`${clsx(classes.root, className)} minimal-header`}
      color="primary"
      position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={allo_logo} style={{ width: '110px' }} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
