import React, { FC, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { formatISO } from 'date-fns';
import apiRoutes from '../../config/apiRoutes';
import {
  CategoriesType,
  BrandsType,
  DateType,
  TDocumentTypes,
  DataType,
} from './DocumentDataTypes';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import { resolveError } from '../../utils/resolveError';
import CustomAutocomplate from '../../utils/CustomAutocomplate';
import DownloadFile from '../../utils/DownloadFile';
import MaterialDatePicker from '../../utils/MaterialDatePicker';
import FullPageSpinner from '../../utils/FullPageSpinner';
import PageTitle from '../../utils/PageTitle';

//ui
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  CircularProgress,
  withStyles,
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

//styles
import DocumentStyles from './DocumentStyles';

interface RouteParams {
  id: string;
}

type Props = {
  classes: {
    formControl?: string;
    spinner?: string;
    saveBtn?: string;
    backLink: string;
  };
};

type DocumentState = {
  data: {
    document_number: string;
    keywords: string;
    document_type: DataType;
    categories: Array<DataType>;
    brand: DataType;
    issued_on: Date;
    expires_on: Date;
    path: string;
  } | null;
  loading: boolean;
};

const MAX_FILE_SIZE = 10485760; // 10 mb

const EditDocument: FC<Props> = ({ classes }) => {
  let { id } = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [document, setDocument] = useState<DocumentState | null>({ data: null, loading: false });
  const [date, setDate] = useState<DateType>({ start: null, end: null });
  const [documentTypes, setDocumentTypes] = useState<TDocumentTypes>({
    data: [],
    current: null,
    loading: true,
  });
  const [categories, setCategories] = useState<CategoriesType>({
    data: [],
    current: [],
    loading: true,
  });
  const [brands, setBrands] = useState<BrandsType>({ data: [], current: null, loading: true });
  const [values, setValues] = useState({ documentNumber: '', keywords: '' });
  const [file, setFile] = useState<null | Blob>(null);
  const [fileName, setFileName] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);

  const handleChangeStartDate = (data: Date) => {
    setDate({ ...date, start: data });
  };

  const handleChangeEndDate = (data: Date) => {
    setDate({ ...date, end: data });
  };

  const handleChangeTypes = (e: React.ChangeEvent<{ value: unknown }>) => {
    setDocumentTypes({ ...documentTypes, current: e.target.value as string });
  };

  const handleChangeValue = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleChangeCategories = (value: Array<DataType>) => {
    setCategories({ ...categories, current: value });
  };

  const handleChangeBrands = (value: DataType) => {
    setBrands({ ...brands, current: value });
  };

  const fetchDocument = useCallback(async () => {
    setDocument({ data: null, loading: true });
    try {
      const { data } = await customAxios.get(`${apiRoutes.DOCUMENTS}/${id}`);
      setDocument({ data: data.data, loading: false });
    } catch (error) {
      dispatch(setSnackbar(true, 'error', resolveError(error.response)));
      setDocument({ data: null, loading: false });
    }
  }, [dispatch, id]);

  const fetchData = useCallback(
    async (apiRoute: string, setState: Function) => {
      try {
        const { data } = await customAxios.get(apiRoute);
        setState({ data: data.data, loading: false });
      } catch (error) {
        dispatch(setSnackbar(true, 'error', resolveError(error.response)));
        setState({ data: [], loading: false });
      }
    },
    [dispatch]
  );

  const handleSaveDocument = async () => {
    const currentCat = categories?.current ?? document?.data?.categories;
    const categoriesId: Array<number> = currentCat.map((cat: { id: number }) => cat.id);
    const brandsId = brands?.current?.id ?? document?.data?.brand?.id;

    const formData = new FormData();

    formData.append('brand_id', String(brandsId));
    formData.append(
      'document_type_id',
      String(documentTypes.current ?? document?.data?.document_type?.id)
    );
    formData.append('categories', JSON.stringify(categoriesId));
    formData.append('document_number', values.documentNumber);
    formData.append('keywords', values.keywords);
    date.start && formData.append('issued_on', formatISO(date.start));
    date.end && formData.append('expires_on', formatISO(date.end));

    if (file && file.size <= MAX_FILE_SIZE) {
      formData.append('file', file as Blob);
    } else if (file) {
      dispatch(setSnackbar(true, 'error', `Выбранный файл: ${fileName} больше 10 мб`));
      return false;
    }

    setBtnLoading(true);
    try {
      const { data } = await customAxios.put(`${apiRoutes.DOCUMENTS}/${id}`, formData);
      dispatch(setSnackbar(true, 'success', data.data.message));
      history.push('/document-catalog');
    } catch (error) {
      dispatch(setSnackbar(true, 'error', resolveError(error.response)));
      console.log(resolveError(error.response));
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchData(apiRoutes.DOCUMENT_TYPES, setDocumentTypes);
    fetchData(apiRoutes.CATEGORIES, setCategories);
    fetchData(apiRoutes.BRANDS, setBrands);

    fetchDocument();
  }, [fetchData, fetchDocument]);

  useEffect(() => {
    if (document?.data) {
      setValues({
        documentNumber: document.data.document_number,
        keywords: document.data.keywords,
      });
    }
  }, [document?.data]);

  if (document?.loading) {
    return <FullPageSpinner show={document.loading} />;
  }

  return (
    <>
      <Link to="/document-catalog" className={classes.backLink}>
        <ChevronLeftIcon />
        Вернуться назад
      </Link>
      {document?.data && (
        <div className="wrap">
          <PageTitle title="Редактирование документа" />
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ margin: '30px auto 10px', maxWidth: '900px' }}>
            <Grid item xs={12} md={3} style={{ padding: '10px', textAlign: 'center' }}>
              <MaterialDatePicker
                label="Дата выпуска"
                date={date.start ?? document.data.issued_on}
                handleChangeDate={handleChangeStartDate}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ padding: '10px', textAlign: 'center' }}>
              <MaterialDatePicker
                label="Дата окончания"
                date={date.end ?? document.data.expires_on}
                handleChangeDate={handleChangeEndDate}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={7}>
              <TextField
                value={values.documentNumber}
                label="Номер документа"
                onChange={handleChangeValue('documentNumber')}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} style={{ textAlign: 'center' }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="edit-type-outlined-label">Тип документа</InputLabel>
                <Select
                  labelId="edit-type-outlined-label"
                  id="edit-type-outlined"
                  value={documentTypes.current ?? document.data.document_type.id}
                  label="Тип документа"
                  onChange={handleChangeTypes}>
                  {documentTypes?.data.map((type: DataType) => {
                    return (
                      <MenuItem value={type.id} key={type.id}>
                        {type.name_ru}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                value={values.keywords}
                label="Ключевые слова"
                onChange={handleChangeValue('keywords')}
                variant="outlined"
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomAutocomplate
                id="edit-categories-checkbox"
                name="name_ru"
                data={categories.data}
                currentData={categories.current ?? document.data.categories}
                changeData={handleChangeCategories}
                label="Категории"
                multiple
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CustomAutocomplate
                id="edit-brands-checkbox"
                name="name"
                data={brands.data}
                currentData={brands.current ?? document.data.brand}
                changeData={handleChangeBrands}
                label="Бренды"
              />
            </Grid>
            <Grid item xs={12}>
              <DownloadFile
                types="image/jpeg, image/png, application/pdf"
                // fileName={fileName.length > 0 ? fileName : document?.data?.path}
                fileName={fileName}
                setFile={setFile}
                setFileName={setFileName}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.saveBtn}
            disabled={btnLoading}
            onClick={handleSaveDocument}
            color="primary"
            variant="contained">
            {btnLoading ? (
              <CircularProgress
                size={25}
                style={{
                  display: 'block',
                  margin: 'auto',
                  color: 'white',
                }}
              />
            ) : (
              'Сохранить'
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default withStyles(DocumentStyles)(EditDocument);
