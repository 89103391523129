import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Typography, Paper, WithStyles } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { authEraseError, login } from '../../store/auth/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Zoom from '@material-ui/core/Zoom';
import { ChangeEvent, FormEvent } from 'react';
import LoginViewStyles from './LoginViewStyles';
import { RootState } from '../../store/rootReducer';

interface LoginViewState {
  login: string;
  password: string;
}

type FormFields = 'login' | 'password';

class LoginView extends React.Component<LoginViewProps, LoginViewState> {
  protected options = {
    title: 'Вход в хранилище документов',
  };

  state = {
    login: '',
    password: '',
  };

  handleFieldChange = (event: ChangeEvent<HTMLInputElement>, field: FormFields) => {
    event.persist();

    if (this.props.auth.error) {
      this.props.authEraseError();
    }

    this.setState((prevState) => {
      return { ...prevState, [field]: event.target.value.trim() };
    });
  };

  handleLogin = (event: FormEvent): void => {
    event.preventDefault();

    if (this.props.auth.loading) return;
    const { login, password } = this.state;
    this.props.login(login, password);
  };

  handleValid = (): boolean => {
    if (this.state.login !== '' && this.state.password !== '') {
      return true;
    }
    return false;
  };

  renderError = (): React.ReactNode | null => {
    if (this.props.auth.error) {
      return (
        <div className="" style={{ color: 'red', marginTop: '15px' }}>
          {this.props.auth.error}
        </div>
      );
    }
    return null;
  };

  render() {
    const { classes } = this.props;

    let buttonText: JSX.Element = <span>Войти</span>;

    if (this.props.auth.loading) {
      buttonText = (
        <React.Fragment>
          <div style={{ position: 'relative' }}>
            <CircularProgress
              size={25}
              style={{
                position: 'absolute',
                left: '-35px',
                top: '2px',
                color: 'white',
              }}
            />
            <span>Войти</span>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="container" id="container">
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid item lg={3}></Grid>
            <Grid className={classes.content} item lg={6} xs={12}>
              <div className={classes.contentBody}>
                <Zoom in={true}>
                  <Paper elevation={5}>
                    <form className={classes.form} onSubmit={this.handleLogin}>
                      <Typography className={classes.title} variant="h2">
                        {this.options.title}
                      </Typography>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        label="Логин"
                        name="login"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          this.handleFieldChange(e, 'login')
                        }
                        type="text"
                        value={this.state.login}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        label="Пароль"
                        name="password"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          this.handleFieldChange(e, 'password')
                        }
                        type="password"
                        value={this.state.password}
                        variant="outlined"
                      />
                      {this.renderError()}
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!this.handleValid()}
                        fullWidth
                        type="submit"
                        variant="contained"
                        style={{ width: '100%', height: '61px' }}>
                        {buttonText}
                      </Button>
                    </form>
                  </Paper>
                </Zoom>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { auth: state.auth };
};

const connector = connect(mapStateToProps, { login, authEraseError });
type PropsFromRedux = ConnectedProps<typeof connector>;
type LoginViewProps = PropsFromRedux & WithStyles<typeof LoginViewStyles>;

export default connector(withStyles(LoginViewStyles)(LoginView));
