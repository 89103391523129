import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import user_logo from '../../../../../../images/user.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props: ProfileProps) => {
  const { auth } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <img alt="Logo" src={user_logo} style={{ width: '80px' }} />
      <Typography className={classes.name} variant="h5" style={{ textAlign: 'center' }}>
        {auth.user.name}
      </Typography>
      {/*<Typography variant="body2">{user.bio}</Typography>*/}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ProfileProps = PropsFromRedux;

export default connector(Profile);
