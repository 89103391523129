import { FC } from 'react';

//utils
import ErrorMessage from '../../../utils/ErrorMessage';
import materialTableLocalization from '../../../utils/materialTableLocalization';

//types
import { ReportType } from '../ReportsTypes';

//ui
import { Paper } from '@material-ui/core';
import MaterialTable from '@material-table/core';

type Column = {
  title: string;
  field: string;
};

type Props = {
  loading: boolean;
  error: string | null;
  data: ReportType[];
  columns: Column[];
  filtering: boolean;
};

const ReportsBody: FC<Props> = (props) => {
  const { loading, error, data, columns, filtering } = props;

  return (
    <Paper elevation={4}>
      {error && !loading ? (
        <ErrorMessage error={error} />
      ) : (
        <MaterialTable
          title=""
          isLoading={loading}
          columns={columns}
          data={data}
          options={{
            filtering: filtering,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            emptyRowsWhenPaging: false,
          }}
          localization={materialTableLocalization}
        />
      )}
    </Paper>
  );
};

export default ReportsBody;
