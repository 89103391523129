import React, { FC } from 'react';

//utils
import MaterialDatePicker from '../../../utils/MaterialDatePicker';
import PageTitle from '../../../utils/PageTitle';

//types
import { DateType } from '../ReportsTypes';

//ui
import { Grid, Button, CircularProgress, withStyles } from '@material-ui/core';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

const ReportsHeaderStyles = () => ({
  wrap: {
    margin: '30px auto 20px',
    maxWidth: '800px',
  },
  btn: {
    height: '36px',
  },
  spinner: {
    color: '#fff',
  },
});

type Props = {
  date: DateType;
  title: string;
  loading: boolean;
  btnLoading: boolean;
  onChangeDate: (type: string) => any;
  fetchReport: () => void;
  getExcelReport?: () => void;
  classes: {
    wrap: string;
    btn: string;
    spinner: string;
  };
};

const ReportsHeader: FC<Props> = (props) => {
  const { loading, btnLoading, classes, date, title, onChangeDate, fetchReport, getExcelReport } =
    props;

  return (
    <>
      <PageTitle title={title} />
      <Grid container alignItems="center" justify="center" className={classes.wrap}>
        <Grid item md className="p10 text-center">
          <MaterialDatePicker label="От" date={date.from} handleChangeDate={onChangeDate('from')} />
        </Grid>
        <Grid item md className="p10 text-center">
          <MaterialDatePicker label="До" date={date.to} handleChangeDate={onChangeDate('to')} />
        </Grid>
        <Grid item xs={6} md className="p10">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={fetchReport}
            disabled={loading}>
            Обновить
          </Button>
        </Grid>

        {getExcelReport && (
          <Grid item md className="p10">
            <Button
              color="primary"
              variant="contained"
              onClick={getExcelReport}
              className={classes.btn}>
              {btnLoading ? (
                <CircularProgress size={20} className={classes.spinner} />
              ) : (
                <SaveAltIcon />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(ReportsHeaderStyles)(ReportsHeader);
