import React, { FC, useState } from 'react';
import apiRoutes from '../../config/apiRoutes';

//ui
import { Button, TextField, CircularProgress, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import CustomDialog from '../../utils/CustomDialog';
import { resolveError } from '../../utils/resolveError';

//styles
import GlobalStyles from '../styles/GlobalStyles';

type Props = {
  classes: {
    documentButton: string;
    documentTextField: string;
    documentSaveButton: string;
  };
  fetchBrands: Function;
};

const AddDocumentBrand: FC<Props> = ({ classes, fetchBrands }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | String>(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const resetForm = () => {
    setError(null);
    setName('');
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    try {
      const { data } = await customAxios.post(apiRoutes.BRANDS, { name });
      dispatch(setSnackbar(true, 'success', data.data.message));
      fetchBrands();
      handleCloseDialog();
      resetForm();
    } catch (error) {
      setError(resolveError(error.response));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.documentButton}
        onClick={() => setOpen(true)}>
        <AddIcon />
      </Button>
      <CustomDialog title="Добавление бренда" openDialog={open} toggleDialog={handleCloseDialog}>
        <form noValidate>
          <TextField
            fullWidth
            label="Название"
            variant="outlined"
            className={classes.documentTextField}
            value={name}
            onChange={handleChangeValue}
          />
          {error && <div className="error">{error}</div>}
          <Button
            className={classes.documentSaveButton}
            color="primary"
            type="submit"
            variant="contained"
            onClick={handleSubmit}>
            {loading && (
              <CircularProgress size={20} style={{ color: 'white', marginRight: '5px' }} />
            )}
            Сохранить
          </Button>
        </form>
      </CustomDialog>
    </>
  );
};

export default withStyles(GlobalStyles)(AddDocumentBrand);
