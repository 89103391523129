import React from 'react';

type Props = {
  error: String | null;
};

const ErrorMessage: React.FC<Props> = ({ error }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '50px',
        marginBottom: '100px',
        padding: '50px 10px',
      }}>
      {error}
    </div>
  );
};

export default ErrorMessage;
