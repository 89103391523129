import { SET_SNACKBAR, SetSnackbarActionTypes } from '../types';

export const setSnackbar = (
  open: boolean,
  typeAlert: string = 'success',
  message: string = ''
): SetSnackbarActionTypes => ({
  type: SET_SNACKBAR,
  open,
  typeAlert,
  message,
});
