import * as React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import RouteWithLayout from './components/RouteWithLayout';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import appRoutes from './config/appRoutes';

//pages
import LoginView from './components/LoginView/LoginView';
import { AddNewDocument, EditDocument, DocumentCatalog } from './components/Document';
import DocumentCategories from './components/DocumentCategories';
import DocumentBrands from './components/DocumentBrands';
import DocumentTypes from './components/DocumentTypes';
import UnsuccessfulUserRequests from './components/Reports/UnsuccessfulUserRequests';

//utils
import ErrorBoundary from './utils/ErrorBoundary';

interface RoutesProps {
  auth: {
    localChecked: boolean;
    token: string;
  };
}

const Routes = (props: RoutesProps) => {
  let routes = <div className="">checking...</div>;

  if (props.auth.localChecked) {
    if (props.auth.token !== null) {
      // const ProtectedRoute = ({
      //   component: Component,
      //   validator: passValidation,
      //   ...rest
      // }: {
      //   component: any;
      //   validator: () => boolean;
      // }) => {
      //   if (passValidation()) {
      //     return <RouteWithLayout {...rest} component={Component} />;
      //   } else {
      //     return <Redirect to={{ pathname: '/login' }} />;
      //   }
      // };

      routes = (
        <React.Fragment>
          <Switch>
            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <DocumentCatalog />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={appRoutes.DOCUMENT_CATALOG.url}
            />
            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <AddNewDocument />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={appRoutes.ADD_DOCUMENT.url}
            />

            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <EditDocument />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={`${appRoutes.EDIT_DOCUMENT.url}/:id`}
            />

            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <DocumentCategories />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={appRoutes.CATEGORIES.url}
            />

            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <DocumentBrands />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={appRoutes.BRANDS.url}
            />

            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <DocumentTypes />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={appRoutes.DOCUMENT_TYPES.url}
            />

            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <UnsuccessfulUserRequests />
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={appRoutes.UNSUCCESSFUL_USER_REQUESTS.url}
            />

            <RouteWithLayout
              component={(props: any) => (
                <ErrorBoundary>
                  <div>main</div>
                </ErrorBoundary>
              )}
              layout={MainLayout}
              exact
              path={'/main'}
            />

            <Redirect to={appRoutes.DOCUMENT_CATALOG.url} />
          </Switch>
        </React.Fragment>
      );
    } else {
      routes = (
        <React.Fragment>
          <Switch>
            <RouteWithLayout
              component={LoginView}
              layout={MinimalLayout}
              exact
              path={appRoutes.LOGIN.url}
            />
            <Redirect to={appRoutes.LOGIN.url} />
          </Switch>
        </React.Fragment>
      );
    }
  }

  return routes;
};

export default Routes;
