import React from 'react';

//ui
import { Button } from '@material-ui/core';

type Props = {
  types: string;
  fileName: string;
  setFile: Function;
  setFileName: Function;
};

const DownloadFile: React.FC<Props> = (props) => {
  const { types, fileName, setFile, setFileName } = props;

  return (
    <div style={{ marginBottom: '10px' }}>
      <input
        style={{ display: 'none' }}
        id="contained-button-file"
        accept={types}
        type="file"
        onChange={(e) => {
          const file = e.target.files ? e.target.files[0] : null;
          if (file) {
            setFile(file);
            setFileName(file.name);
          }
        }}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="secondary"
          component="span"
          style={{ fontSize: '10px', marginRight: '10px' }}>
          Выбрать файл
        </Button>
      </label>
      <span>{fileName ? `Выбранный файл: ${fileName}` : ''}</span>
    </div>
  );
};

export default DownloadFile;
