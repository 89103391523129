import React from 'react';
import ru from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';

//ui
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

type Props = {
  label: string;
  date: number | null | Date;
  handleChangeDate: Function;
};

const MaterialDatePicker: React.FC<Props> = (props) => {
  const { label, date, handleChangeDate } = props;

  const handleChange = (date: Date | null) => handleChangeDate(date);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
      <KeyboardDatePicker
        autoOk
        inputVariant="outlined"
        value={date}
        placeholder="26.03.2021"
        onChange={handleChange}
        label={label}
        format="dd.MM.yyyy"
        invalidDateMessage="Недопустимый формат даты"
        cancelLabel="отмена"
      />
    </MuiPickersUtilsProvider>
  );
};

export default MaterialDatePicker;
