import React from 'react';

//ui
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

//icons
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

type Props = {
  id: string;
  name: string;
  data: any;
  label: string;
  currentData: any;
  changeData: Function;
  multiple?: boolean;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutocomplate: React.FC<Props> = (props) => {
  const { data, label, currentData, id, changeData, multiple, name } = props;

  const handleChange = (e: any, value: any) => {
    changeData(value);
  };

  return (
    <>
      {multiple ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={id}
          options={data}
          value={currentData ?? []}
          getOptionSelected={(option, value) => option.id === value?.id}
          getOptionLabel={(option: any) => option[name]}
          onChange={handleChange}
          noOptionsText="Ничего не найдено"
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                color="primary"
                checked={selected}
              />
              {option[name]}
            </React.Fragment>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
        />
      ) : (
        <Autocomplete
          id={id}
          options={data}
          onChange={handleChange}
          getOptionLabel={(option: any) => option[name]}
          value={currentData ?? null}
          getOptionSelected={(option, value) => option.id === value?.id}
          noOptionsText="Ничего не найдено"
          renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
      )}
    </>
  );
};

export default React.memo(CustomAutocomplate);
