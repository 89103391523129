import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './Routes';
import theme from './theme';
import { authCheckState } from './store/auth/actions';
import './App.scss';

class App extends React.Component<AppProps> {
  componentDidMount() {
    this.props.authCheckState();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes auth={this.props.auth} />
        </Router>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const connector = connect(mapStateToProps, { authCheckState });
type PropsFromRedux = ConnectedProps<typeof connector>;
type AppProps = PropsFromRedux;

export default connector(App);
