import { User } from '../../../models/User';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_AUTO_SUCCESS = 'AUTH_AUTO_SUCCESS';
export const AUTH_AUTO_FAIL = 'AUTH_AUTO_FAIL';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_ERASE_ERROR = 'AUTH_ERASE_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

type AuthStartAction = { type: typeof AUTH_START };
type AuthSuccessAction = { type: typeof AUTH_SUCCESS; payload: any };
type AuthAutoSuccessAction = { type: typeof AUTH_AUTO_SUCCESS; token: string; user: User };
type AuthAutoFailAction = { type: typeof AUTH_AUTO_FAIL };
type AuthFailAction = { type: typeof AUTH_FAIL; error: object };
type AuthEraseErrorAction = { type: typeof AUTH_ERASE_ERROR };
type AuthLogoutAction = { type: typeof AUTH_LOGOUT };

export type AuthActionTypes =
  | AuthStartAction
  | AuthSuccessAction
  | AuthAutoSuccessAction
  | AuthAutoFailAction
  | AuthFailAction
  | AuthEraseErrorAction
  | AuthLogoutAction;

export type AuthState = {
  token: string | null;
  user: User | null;
  error: string | Array<any> | null;
  loading: boolean;
  localChecked: boolean;
};
