import React from 'react';
// import { makeStyles, Theme } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     padding: theme.spacing(4),
//   },
// }));

interface FooterProps {}

const Footer = (props: FooterProps) => {
  const { ...rest } = props;
  // const classes = useStyles();
  return <div {...rest}></div>;
};

export default Footer;
