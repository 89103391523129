import React, { FC } from 'react';

//ui
import { Dialog, DialogContent, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

//styles
import CustomDialogStyles from './CustomDialogStyles';

type Props = {
  title: string;
  openDialog: boolean;
  toggleDialog: Function;
  children: React.ReactNode;
};

type DialogTitleProps = {
  children: React.ReactNode;
  onClose: () => void;
  classes: {
    title: string;
    closeButton: string;
  };
};

const DialogTitle = withStyles(CustomDialogStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomDialog: FC<Props> = ({ title, openDialog, toggleDialog, children }) => {
  const handleDialogClose = () => {
    toggleDialog(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={openDialog} onClose={handleDialogClose}>
      <DialogTitle onClose={handleDialogClose}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default withStyles(CustomDialogStyles)(CustomDialog);
