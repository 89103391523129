import { resolveError } from '../../../utils/resolveError';
import {
  AUTH_AUTO_FAIL,
  AUTH_AUTO_SUCCESS,
  AUTH_ERASE_ERROR,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_START,
  AUTH_SUCCESS,
  AuthActionTypes,
  AuthState,
} from '../types';

const initState: AuthState = {
  token: null,
  user: null,
  error: null,
  loading: false,
  localChecked: false,
};

const AuthReducer = (state = initState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AUTH_ERASE_ERROR:
      return { ...state, error: null };

    case AUTH_START:
      return { ...state, error: null, loading: true };

    case AUTH_AUTO_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user,
        localChecked: true,
      };

    case AUTH_AUTO_FAIL:
      return { ...state, localChecked: true };

    case AUTH_SUCCESS:
      const { token, user } = action.payload;
      return { ...state, token, user, loading: false };

    case AUTH_FAIL:
      return { ...state, token: null, error: resolveError(action.error), loading: false };

    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        error: null,
        loading: false,
        localChecked: true,
      };

    default:
      return state;
  }
};

export default AuthReducer;
