import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { formatISO } from 'date-fns';
import apiRoutes from '../../config/apiRoutes';
import {
  CategoriesType,
  BrandsType,
  DateType,
  TDocumentTypes,
  DataType,
} from './DocumentDataTypes';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import { resolveError } from '../../utils/resolveError';
import CustomAutocomplate from '../../utils/CustomAutocomplate';
import DownloadFile from '../../utils/DownloadFile';
import MaterialDatePicker from '../../utils/MaterialDatePicker';
import PageTitle from '../../utils/PageTitle';

//ui
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  CircularProgress,
  TextField,
  FormHelperText,
  withStyles,
} from '@material-ui/core';

//styles
import DocumentStyles from './DocumentStyles';

type Props = {
  classes: {
    formControl: string;
    spinner: string;
    saveBtn: string;
  };
};

const MAX_FILE_SIZE = 10485760; // 10 mb

const AddDocument: React.FC<Props> = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentTypes, setDocumentTypes] = useState<TDocumentTypes>({
    data: [],
    current: null,
    loading: true,
  });
  const [categories, setCategories] = useState<CategoriesType>({
    data: [],
    current: [],
    loading: true,
  });
  const [brands, setBrands] = useState<BrandsType>({ data: [], current: null, loading: true });
  const [values, setValues] = useState({ documentNumber: '', keywords: '' });
  const [file, setFile] = useState<null | Blob>(null);
  const [fileName, setFileName] = useState('');
  const [date, setDate] = useState<DateType>({ start: null, end: null });
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChangeTypes = (e: React.ChangeEvent<{ value: unknown }>) => {
    setDocumentTypes({ ...documentTypes, current: e.target.value as string });
  };

  const handleChangeCategories = (value: Array<DataType>) => {
    setCategories({ ...categories, current: value });
  };

  const handleChangeBrands = (value: DataType) => {
    setBrands({ ...brands, current: value });
  };

  const handleChangeValue = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleChangeStartDate = (data: Date) => {
    setDate({ ...date, start: data });
  };

  const handleChangeEndDate = (data: Date) => {
    setDate({ ...date, end: data });
  };

  const handleValid = () => {
    if (
      fileName &&
      documentTypes.current &&
      categories.current &&
      brands.current &&
      values.keywords
    ) {
      return true;
    }
  };

  const handleAddNewDocument = async () => {
    if (btnLoading) return;

    if (!handleValid()) {
      setError(true);
    } else {
      setError(false);
      if (file && file.size <= MAX_FILE_SIZE) {
        const categoriesId: Array<number> = categories?.current?.map(
          (cat: { id: number }) => cat.id
        );
        const brandsId = brands.current !== null && brands.current.id;

        const formData = new FormData();
        formData.append('file', file as Blob);
        formData.append('brand_id', String(brandsId));
        formData.append('document_type_id', String(documentTypes.current));
        formData.append('categories', JSON.stringify(categoriesId));
        formData.append('document_number', values.documentNumber);
        formData.append('keywords', values.keywords);

        if (date.start !== null && date.end !== null) {
          formData.append('issued_on', formatISO(date.start));
          formData.append('expires_on', formatISO(date.end));
        }

        setBtnLoading(true);
        try {
          const { data } = await customAxios.post(apiRoutes.DOCUMENTS_CREATE_FILE, formData);
          dispatch(setSnackbar(true, 'success', data.data.message));
          history.push('/');
        } catch (error) {
          dispatch(setSnackbar(true, 'error', resolveError(error.response)));
        } finally {
          setBtnLoading(false);
        }
      } else {
        dispatch(setSnackbar(true, 'error', `Выбранный файл: ${fileName} больше 10 мб`));
      }
    }
  };

  const fetchData = useCallback(
    async (apiRoute: string, setState: Function) => {
      try {
        const { data } = await customAxios.get(apiRoute);
        setState({ data: data.data, loading: false });
      } catch (error) {
        dispatch(setSnackbar(true, 'error', resolveError(error.response)));
        setState({ data: [], loading: false });
      }
    },
    [dispatch]
  );

  const renderFieldError = (text: string) => {
    return (
      <FormHelperText margin="dense" error>
        {text}
      </FormHelperText>
    );
  };

  useEffect(() => {
    fetchData(apiRoutes.DOCUMENT_TYPES, setDocumentTypes);
    fetchData(apiRoutes.CATEGORIES, setCategories);
    fetchData(apiRoutes.BRANDS, setBrands);
  }, [fetchData]);

  return (
    <div className="wrap">
      <PageTitle title="Добавление нового документа" />
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ margin: '30px auto 10px', maxWidth: '900px' }}>
        <Grid item xs={12} md={3} style={{ padding: '10px', textAlign: 'center' }}>
          <MaterialDatePicker
            label="Дата выпуска"
            date={date.start}
            handleChangeDate={handleChangeStartDate}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: '10px', textAlign: 'center' }}>
          <MaterialDatePicker
            label="Дата окончания"
            date={date.end}
            handleChangeDate={handleChangeEndDate}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={6} md={7}>
          <TextField
            value={values.documentNumber}
            label="Номер документа"
            onChange={handleChangeValue('documentNumber')}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={5} style={{ textAlign: 'center' }}>
          {documentTypes.data.length > 0 && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="type-outlined-label">Тип документа</InputLabel>
              <Select
                labelId="type-outlined-label"
                id="type-outlined"
                value={documentTypes.current ?? ''}
                label="Тип документа"
                onChange={handleChangeTypes}>
                {documentTypes?.data.map((type: DataType) => {
                  return (
                    <MenuItem value={type.id} key={type.id}>
                      {type.name_ru}
                    </MenuItem>
                  );
                })}
              </Select>
              {error &&
                !documentTypes.current &&
                renderFieldError('Поле "Тип документа" обязательно для заполнения')}
            </FormControl>
          )}
          {documentTypes.loading && <CircularProgress className={classes.spinner} size={30} />}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            value={values.keywords}
            label="Ключевые слова"
            onChange={handleChangeValue('keywords')}
            variant="outlined"
            fullWidth
            multiline
          />
          {error &&
            !values.keywords &&
            renderFieldError('Поле "Ключевые слова" обязательно для заполнения')}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {categories.data.length > 0 && (
            <CustomAutocomplate
              id="categories-checkbox"
              name="name_ru"
              data={categories.data}
              currentData={categories.current}
              changeData={handleChangeCategories}
              label="Категории"
              multiple
            />
          )}
          {categories.loading && <CircularProgress className={classes.spinner} size={30} />}
          {error &&
            !categories.current &&
            renderFieldError('Поле "Категории" обязательно для заполнения')}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {brands.data.length > 0 && (
            <CustomAutocomplate
              id="brands-checkbox"
              name="name"
              data={brands.data}
              currentData={brands.current}
              changeData={handleChangeBrands}
              label="Бренды"
            />
          )}
          {brands.loading && <CircularProgress className={classes.spinner} size={30} />}
          {error && !brands.current && renderFieldError('Поле "Бренды" обязательно для заполнения')}
        </Grid>

        <Grid item xs={12}>
          <DownloadFile
            types="image/jpeg, image/png, application/pdf"
            fileName={fileName}
            setFile={setFile}
            setFileName={setFileName}
          />
          {error && !fileName && renderFieldError('Поле "Выбрать файл" обязательно для заполнения')}
        </Grid>
      </Grid>
      <Button
        className={classes.saveBtn}
        onClick={handleAddNewDocument}
        color="primary"
        variant="contained">
        {btnLoading ? (
          <CircularProgress
            size={25}
            style={{
              display: 'block',
              margin: 'auto',
              color: 'white',
            }}
          />
        ) : (
          'Добавить'
        )}
      </Button>
    </div>
  );
};

export default withStyles(DocumentStyles)(AddDocument);
