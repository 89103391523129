export const SET_SNACKBAR = 'SET_SNACKBAR';

type SetSnackbarAction = {
  type: typeof SET_SNACKBAR;
  open: boolean;
  typeAlert: string;
  message: string;
};

export type SetSnackbarActionTypes = SetSnackbarAction;

export type SnackbarState = {
  open: boolean;
  typeAlert: string;
  message: string;
};
