import { createStyles, Theme } from '@material-ui/core/styles';

const SidebarNavStyles = (theme: Theme) =>
  createStyles({
    root: {},
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      // color: colors.blueGrey[800],
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.main,
      },
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },
    collapseIcon: {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: '0',
    },
  });

export default SidebarNavStyles;
