const appRoutes = {
  LOGIN: {
    url: '/login',
    name: 'Вход',
  },
  DOCUMENT_CATALOG: {
    url: '/document-catalog',
    name: 'Все документы',
  },
  ADD_DOCUMENT: {
    url: '/add-document',
    name: 'Добавить новый',
  },
  EDIT_DOCUMENT: {
    url: '/edit-document',
    name: 'Редактирование документа',
  },
  CATEGORIES: {
    url: '/categories',
    name: 'Категории',
  },
  BRANDS: {
    url: '/brands',
    name: 'Бренды',
  },
  DOCUMENT_TYPES: {
    url: '/document_types',
    name: 'Типы документов',
  },

  UNSUCCESSFUL_USER_REQUESTS: {
    url: '/reports/unsuccessful-user-requests',
    name: 'Неудачные запросы',
  },

  LOGOUT: {
    url: '/logout',
    name: 'Выход',
  },
};

export default appRoutes;
