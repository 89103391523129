type FunctionReturn = string;

export const resolveError = (
  error: any,
  defaultErrorMessage: string = 'Ошибка сервера'
): FunctionReturn => {
  const errorResponse = error.data;

  if (error.status === 500) {
    return defaultErrorMessage;
  } else if (errorResponse.message) {
    return errorResponse.trace ? defaultErrorMessage : errorResponse.message;
  } else if (errorResponse.error) {
    return handleErrorResponse(errorResponse.error, defaultErrorMessage);
  } else if (errorResponse.status) {
    return handleErrorResponse(errorResponse.status, defaultErrorMessage);
  }
  return defaultErrorMessage;
};

function handleErrorResponse(error: any, defaultErrorMessage: string): FunctionReturn {
  if (typeof error === 'object') {
    return Object.values(error).join('; ');
  } else if (typeof error === 'string') {
    return error;
  }
  return defaultErrorMessage;
}
