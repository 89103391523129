import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Hidden, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { connect, ConnectedProps } from 'react-redux';
import { logout } from '../../../../store/auth/actions';
import allo_logo from '../../../../images/logo_allo_white.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props: TopbarProps) => {
  const { onSidebarOpen } = props;

  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={allo_logo} style={{ width: '110px' }} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const connector = connect(mapStateToProps, { logout });
type PropsFromRedux = ConnectedProps<typeof connector>;
type TopbarProps = PropsFromRedux & { onSidebarOpen: () => void };

export default connector(Topbar);
