import React from 'react';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { setSnackbar } from '../../store/snackbar/actions';

//ui
import { Snackbar, withStyles } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

//styles
import CustomizedSnackbarsStyles from './CustomizedSnackbarsStyles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Props = {
  classes: {
    root: string;
    snackBar: string;
  };
};

const CustomizedSnackbars: React.FC<Props> = ({ classes }) => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: RootState) => state.snackbar);

  const { open, typeAlert, message } = snackbar;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbar(false, typeAlert, message));
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose} className={classes.snackBar} severity={typeAlert as any}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withStyles(CustomizedSnackbarsStyles)(CustomizedSnackbars);
