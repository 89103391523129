import React, { FC, useState } from 'react';
import apiRoutes from '../../config/apiRoutes';

//ui
import { Button, TextField, CircularProgress, withStyles } from '@material-ui/core';

//redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/snackbar/actions';

//utils
import customAxios from '../../utils/customAxios';
import CustomDialog from '../../utils/CustomDialog';
import { resolveError } from '../../utils/resolveError';

//styles
import GlobalStyles from '../styles/GlobalStyles';

type Props = {
  id: number;
  brandName: string;
  open: boolean;
  setOpen: Function;
  fetchBrands: Function;
  classes: {
    documentTextField: string;
    documentSaveButton: string;
  };
};

const EditDocumentBrand: FC<Props> = (props) => {
  const { classes, id, brandName, open, setOpen, fetchBrands } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState(brandName);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | String>(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    try {
      const { data } = await customAxios.put(`${apiRoutes.BRANDS}/${id}`, { name });
      dispatch(setSnackbar(true, 'success', data.data.message));
      fetchBrands();
      handleCloseDialog();
    } catch (error) {
      setError(resolveError(error.response));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDialog title="Редактирование бренда" openDialog={open} toggleDialog={handleCloseDialog}>
      <form noValidate>
        <TextField
          fullWidth
          label="Название"
          variant="outlined"
          className={classes.documentTextField}
          value={name}
          onChange={handleChangeValue}
        />
        {error && <div className="error">{error}</div>}
        <Button
          className={classes.documentSaveButton}
          color="primary"
          type="submit"
          variant="contained"
          onClick={handleSubmit}>
          {loading && <CircularProgress size={20} style={{ color: 'white', marginRight: '5px' }} />}
          Сохранить
        </Button>
      </form>
    </CustomDialog>
  );
};

export default withStyles(GlobalStyles)(EditDocumentBrand);
