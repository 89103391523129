import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './FullPageSpinner.scss';

interface FullPageSpinnerProps {
  show: boolean;
}

function FullPageSpinner(props: FullPageSpinnerProps) {
  return (
    <div className={`FullPageSpinner ${props.show ? 'show' : ''}`}>
      <CircularProgress size={50} />
    </div>
  );
}

export default FullPageSpinner;
