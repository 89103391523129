const apiRoutes = {
  LOGIN: '/auth/login',
  CATEGORIES: '/categories',
  BRANDS: '/brands',
  DOCUMENT_TYPES: '/document-types',
  DOCUMENTS_CREATE_FILE: '/documents',
  DOCUMENTS: '/documents',
  REQUESTS_HISTORY_GET_EXCEL_REPORT: '/requests-history/get-excel-report-by-period',
  REQUESTS_HISTORY_FIND_BY_PERIOD: '/requests-history/find-by-period',
};

export default apiRoutes;
