import React from 'react';
import clsx from 'clsx';
import appRoutes from '../../../../config/appRoutes';

import { Profile, SidebarNav } from './components';

//ui
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';

//icons
import DescriptionIcon from '@material-ui/icons/Description';
import InputIcon from '@material-ui/icons/Input';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ArchiveIcon from '@material-ui/icons/Archive';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import FolderIcon from '@material-ui/icons/Folder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    // backgroundColor: theme.palette.white,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

interface SidebarProps {
  open: boolean;
  variant: any;
  onClose: () => void;
}

const Sidebar = (props: SidebarProps) => {
  const { open, variant, onClose, ...rest } = props;
  const classes = useStyles();

  const pages = [
    {
      title: 'Документы',
      icon: <DescriptionIcon />,
      href: '',
      name: 'documents',
      submenu: [
        {
          title: appRoutes.DOCUMENT_CATALOG.name,
          href: appRoutes.DOCUMENT_CATALOG.url,
          icon: <ArchiveIcon />,
        },
        {
          title: appRoutes.ADD_DOCUMENT.name,
          href: appRoutes.ADD_DOCUMENT.url,
          icon: <NoteAddIcon />,
        },
      ],
    },
    {
      title: 'Отчеты',
      icon: <AssignmentIcon />,
      href: '',
      name: 'reports',
      submenu: [
        {
          title: appRoutes.UNSUCCESSFUL_USER_REQUESTS.name,
          href: appRoutes.UNSUCCESSFUL_USER_REQUESTS.url,
          icon: <AssignmentLateIcon />,
        },
      ],
    },
    {
      title: appRoutes.CATEGORIES.name,
      href: appRoutes.CATEGORIES.url,
      icon: <FolderIcon />,
    },
    {
      title: appRoutes.BRANDS.name,
      href: appRoutes.BRANDS.url,
      icon: <LocalOfferIcon />,
    },
    {
      title: appRoutes.DOCUMENT_TYPES.name,
      href: appRoutes.DOCUMENT_TYPES.url,
      icon: <LibraryBooksIcon />,
    },
    {
      title: appRoutes.LOGOUT.name,
      href: appRoutes.LOGOUT.url,
      icon: <InputIcon />,
    },
  ];

  const pagesWithPermission = pages.filter((page) => {
    return true;
  });

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pagesWithPermission} />
      </div>
    </Drawer>
  );
};

export default Sidebar;
